import React, { useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Cta from "../components/cta";
import Button from "../components/button";

import {
    // Link,
    graphql,
} from "gatsby";
import PropTypes from "prop-types";

import NumberFormat from "react-number-format";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

function Rupiah({ amount, prefix = true }) {
    return (
        <NumberFormat
            value={amount}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={prefix ? "Rp" : ""}
        />
    );
}

function Pricing({ data }) {
    const [expandDetail, setExpandDetail] = useState(true);
    let d = data.contentfulPricing;
    let periodeExpired = [
        {
            name: "Bulanan",
            duration: 1,
            discount: 0,
        },
        {
            name: "3 Bulan",
            duration: 3,
            discount: 10,
        },
        {
            name: "1 Tahun",
            duration: 12,
            discount: 25,
        },
    ];
    let packages = [
        {
            name: "Freemium",
            description: "Skala bisinis kecil",
            link: `${process.env.GATSBY_NGORDER_APP_URL}/register?package=freemium`,
        },
        {
            name: "Silver",
            description: "Skala bisinis menengah",
            link: `${process.env.GATSBY_NGORDER_APP_URL}/register?package=premium`,
        },
        {
            name: "Gold",
            description: "Skala bisinis besar",
            link: `${process.env.GATSBY_NGORDER_APP_URL}/register?package=platinum`,
        },
    ];

    return (
        <Layout>
            <SEO title={d.title} description={d.subtitle} />
            <section className="bg-secondary dark:bg-gray-800">
                <div className="flex flex-col justify-center items-center pt-20">
                    <div className="mb-8 max-w-3xl">
                        <h2 className="font-display font-bold mb-3 text-[40px] lg:text-5xl text-center leading-tight">
                            {d.title}
                        </h2>
                        <p className="text-center text-2xl">{d.subtitle}</p>
                    </div>
                    <div className="w-full flex justify-center px-4 mx-auto">
                        <Tabs className="w-full" defaultIndex={0}>
                            <TabList className="w-full lg:w-[525px] bg-white border border-1 border-[#2125291A] rounded-lg p-1 relative flex mx-auto">
                                {periodeExpired.map((item, i) => (
                                    <Tab
                                        key={i}
                                        index={i}
                                        className="w-1/3 lg:w-[178px] py-2 text-xs lg:text-sm font-bold transition-all duration-150 rounded-lg outline-none focus:outline-none flex items-center cursor-pointer justify-center mb-0"
                                        selectedClassName="bg-primary text-white"
                                        disabledClassName="text-gray-600"
                                    >
                                        {item.name}
                                        {item.discount ? (
                                            <span className="bg-red-100 text-red-800 text-[8px] lg:text-[10px] font-bold ml-0.5 px-1.5 rounded-xl dark:bg-red-900 dark:text-red-300">
                                                Diskon {item.discount}%
                                            </span>
                                        ) : (
                                            <span></span>
                                        )}
                                    </Tab>
                                ))}
                            </TabList>

                            {[0, 1, 2, 3].map((i) => (
                                <TabPanel key={i} index={i}>
                                    <div className="w-full mx-auto max-w-[1328px] px-0 py-8 md:px-0 md:py-12 paragraph flex-row md:flex md:space-x-6">
                                        {d.content.package.map((item) => (
                                            <div
                                                key={item.title}
                                                className={`${
                                                    item.featured === false
                                                        ? "bg-white dark:bg-gray-700 dark:text-gray-200"
                                                        : "dark:text-gray-100 dark:bg-primary bg-[#002447]"
                                                } px-8 py-6 rounded-lg shadow-lg flex-1 flex flex-col justify-between mb-6 relative md:w-4/12`}
                                            >
                                                {item.featured === true ? (
                                                    <div className="absolute -top-3 left-6 bg-[#FFDB4C] text-primary text-sm font-bold rounded-full px-3 flex items-center">
                                                        <span className="text-[#3880FF] flex items-center px-4 py-1">
                                                            Populer!
                                                            <img
                                                                className="w-4 h-4 ml-1"
                                                                src="../icons/star-featured.svg"
                                                            />
                                                        </span>
                                                    </div>
                                                ) : null}

                                                <div
                                                    style={{
                                                        color: item.featured
                                                            ? "white"
                                                            : "",
                                                    }}
                                                >
                                                    <h2 className="font-display font-bold mb-2 dark:text-white text-xl">
                                                        {item.title}
                                                    </h2>
                                                    <p
                                                        className={`text-sm ${
                                                            item.featured
                                                                ? "text-white"
                                                                : "text-gray-500"
                                                        } h-8`}
                                                    >
                                                        {item.description}
                                                    </p>
                                                    <div className="mt-10 mb-6 w-full h-[72px] flex items-end">
                                                        {item.price[i]
                                                            ?.amount >= 0 &&
                                                        item.name !==
                                                            "enterprise" ? (
                                                            <div
                                                                className={`relative w-auto inline-block text-[32px] leading-[48px] font-bold item.featured ? "text-white" : "text-primary"`}
                                                            >
                                                                <span>Rp</span>
                                                                <Rupiah
                                                                    amount={
                                                                        item
                                                                            .price[
                                                                            i
                                                                        ].amount
                                                                    }
                                                                    prefix={
                                                                        false
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="relative w-auto inline-block text-xl leading-[24px] font-bold text-primary">
                                                                Hubungi kami
                                                                untuk info lebih
                                                                lanjut!
                                                            </div>
                                                        )}
                                                    </div>

                                                    <p className="text-sm font-bold mb-3">
                                                        Keuntungan yang didapat:
                                                    </p>
                                                    <ul className="list-none grid gap-y-3 ml-0">
                                                        {item.feature.map(
                                                            (
                                                                feature,
                                                                index
                                                            ) => (
                                                                <li
                                                                    key={index}
                                                                    className="text-sm flex flex-row"
                                                                >
                                                                    <img
                                                                        className="w-4 h-4 mr-2.5"
                                                                        src={
                                                                            item.featured
                                                                                ? "../icons/check-white.svg"
                                                                                : "../icons/check-black.svg"
                                                                        }
                                                                    />
                                                                    {feature}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                {item.title == "Enterprise" ? (
                                                    <Button
                                                        text="Hubungi kami"
                                                        size="lg"
                                                        color="outline"
                                                        url="https://wa.me/6281398859343?text=Hai%20SmartSeller%2C%0ASaya%20tertarik%20untuk%20paket%20Enterprise."
                                                    />
                                                ) : (
                                                    <Button
                                                        text="Pilih Paket"
                                                        size="lg"
                                                        color="primary"
                                                        url={`${
                                                            process.env
                                                                .GATSBY_NGORDER_APP_URL
                                                        }/register?package=${
                                                            item.name
                                                        }${
                                                            item.name !==
                                                            "freemium"
                                                                ? "-" +
                                                                  item.price[i]
                                                                      ?.time
                                                                : ""
                                                        }`}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </TabPanel>
                            ))}
                        </Tabs>
                    </div>
                    <a
                        onClick={() => setExpandDetail(!expandDetail)}
                        className="mb-9 text-lg text-primary font-bold cursor-pointer hover:underline"
                    >
                        {expandDetail
                            ? "Sembunyikan"
                            : "Lihat perbandingan selengkapnya"}
                    </a>
                    <div
                        className={`max-w-6xl w-full m-[60px] relative transition-all ${
                            !expandDetail ? "hidden" : ""
                        }`}
                    >
                        <div className="flex justify-end sticky top-4">
                            <div className="bg-white w-[65.7%] lg:w-[63.7%] text-center text-xs lg:text-sm flex divide-x divide-gray-200 rounded-lg">
                                {packages.map((item) => (
                                    <div
                                        className="w-1/3 px-2 py-4 lg:p-6 flex flex-col items-center gap-3"
                                        key={`option-${item.name}`}
                                    >
                                        <div className="absolute -top-3  bg-[#FFDB4C] text-primary font-bold rounded-full px lg:px-3 flex items-center">
                                            <span
                                                className={`text-[#3880FF] text-[10px] lg:text-sm flex items-center px-4 py-1 ${
                                                    item.name == "Gold"
                                                        ? ""
                                                        : "hidden"
                                                }`}
                                            >
                                                Populer!
                                                <img
                                                    className="w-4 h-4 ml-1"
                                                    src="../icons/star-featured.svg"
                                                />
                                            </span>
                                        </div>
                                        <p className="text-base lg:text-xl font-bold">
                                            {item.name}
                                        </p>
                                        <p className="text-gray-400">
                                            {item.description}
                                        </p>
                                        <a
                                            href={item.link}
                                            target="__blank"
                                            className="w-full lg:w-2/3 bg-primary text-center text-white rounded-lg px-2 py-1 lg:p-2"
                                        >
                                            Pilih Paket
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <table className="table-auto text-xs lg:text-sm bg-white w-full divide-y divide-gray-200 ">
                            {d.pricingTable.map((content, index) => (
                                <>
                                    <thead
                                        className="font-bold bg-[#F4F4F4]"
                                        key={`table-head-${index}`}
                                    >
                                        <tr>
                                            <th
                                                colSpan={4}
                                                className="px-6 py-3 text-left"
                                            >
                                                {content.name}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {content.features.map((feature) => (
                                            <tr
                                                className="divide-x divide-gray-200"
                                                key={`table-body-${index}`}
                                            >
                                                <td className="px-4 lg:px-6 py-3">
                                                    {feature.title}
                                                </td>
                                                {feature.datas.length == 1
                                                    ? feature.datas.map(
                                                          (data, index) => (
                                                              <td
                                                                  colSpan={3}
                                                                  className="text-center px-6 py-3"
                                                                  key={`table-data-${index}`}
                                                              >
                                                                  {data}
                                                              </td>
                                                          )
                                                      )
                                                    : feature.datas.map(
                                                          (data, index) => (
                                                              <td
                                                                  className="px-4 lg:px-6 py-3"
                                                                  align="center"
                                                                  key={`table-data-${index}`}
                                                              >
                                                                  {!data.length ? (
                                                                      <img
                                                                          className="w-4 h-4"
                                                                          src="../icons/cancel-red.svg"
                                                                      />
                                                                  ) : data ==
                                                                    "true" ? (
                                                                      <img
                                                                          className="w-4 h-4"
                                                                          src="../icons/check-green.svg"
                                                                      />
                                                                  ) : (
                                                                      data
                                                                  )}
                                                              </td>
                                                          )
                                                      )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </>
                            ))}
                        </table>
                    </div>
                </div>
            </section>

            <Cta />
        </Layout>
    );
}

Rupiah.propTypes = {
    amount: PropTypes.string,
    prefix: PropTypes.boolean,
};

Pricing.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulPricing {
            title
            subtitle
            content {
                addons {
                    addons_list {
                        description
                        title
                        icon
                        hasPackage
                        canTrial
                    }
                    description
                    title
                }
                package {
                    description
                    featured
                    feature
                    price {
                        package
                        amount
                        time
                    }
                    title
                    name
                }
            }
            pricingTable {
                name
                features {
                    title
                    datas
                }
            }
            faq {
                question
                answer
            }
        }
    }
`;

export default Pricing;
